import React, { useState } from 'react';
import Script from 'next/script';
import Link from 'next/link';
import Head from 'next/head';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const { API_URL, ANALYTICS } = publicRuntimeConfig;
const defaultDescription =
  'Host your own Track Night and have a night of fast-paced fun. Invite all your mates and have some fun cheering on the horses. Perfect for parties and team building events!';
const defaultTitle = 'Host your own horse racing night, in person or online';

const Layout = (ctx) => {
  const { children, brand, canonical } = ctx;
  const { ogImage, ogTitle, ogDescription } = ctx;
  const [menuVisible, setMenuVisible] = useState(false);

  const showMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div>
      <Script src="https://cdn.jsdelivr.net/npm/text-encoding@0.6.4/lib/encoding.min.js"></Script>
      <Head>
        <script src="https://cdn.paddle.com/paddle/paddle.js"></script>
        <title>{ogTitle || defaultTitle}</title>
        {canonical ? <link rel="canonical" href={canonical} /> : null}
        <meta
          name="description"
          content={ogDescription ? ogDescription : defaultDescription}
        ></meta>
        <meta
          name="twitter:card"
          content={ogImage ? 'summary_large_image' : 'summary'}
        />
        <meta
          property="og:image"
          content={
            ogImage
              ? `${API_URL}/${ogImage}`
              : `${API_URL}/logo-track-night.png`
          }
        />
        <meta
          property="twitter:image"
          content={
            ogImage
              ? `${API_URL}/${ogImage}`
              : `${API_URL}/logo-track-night.png`
          }
        />
        <meta name="twitter:title" content={ogTitle ? ogTitle : defaultTitle} />
        <meta
          name="twitter:description"
          content={ogDescription ? ogDescription : defaultDescription}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${
            (brand && brand.gaId) || 'UA-164040019-1'
          }`}
        ></script>
        {ANALYTICS ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', ${
              brand && brand.gaId ? `'${brand.gaId}'` : '"UA-164040019-1"'
            });`,
            }}
          />
        ) : null}
      </Head>
      <div className={brand && brand.name}>
        <section className="hero banner">
          <div className="hero-body">
            <div className="content container">
              <div className="columns is-mobile">
                <div className="column">
                  <Link passHref href="/" title="Track Night">
                    <h1 className="title"></h1>
                  </Link>
                </div>
                {ctx.showHeaderLinks ? (
                  <div className="column is-4-large-2-small">
                    <div className="right-when-big">
                      <div className="header-links">
                        <ul>
                          <li>
                            <a href="/faq">How it works</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="right-when-small bump-down-17">
                      <a
                        role="button"
                        className={
                          menuVisible
                            ? 'is-active navbar-burger'
                            : 'navbar-burger'
                        }
                        onClick={showMenu}
                        aria-label="menu"
                        aria-expanded="false"
                      >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        {ctx.showHeaderLinks && menuVisible ? (
          <div className="mobile-links hide-large">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/faq">How it works</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        ) : null}
        <div className="main">{children}</div>
        <footer className="footer">
          <div className="content container">
            <div className="columns">
              <div className="column footer-section">
                <h1>Main Navigation</h1>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/faq">How it works</a>
                  </li>
                  <li>
                    <a href="/virtual-horse-race-nights">
                      Virtual Horse Racing
                    </a>
                  </li>
                  <li>
                    <a href="/virtual-office-parties">Remote Work Parties</a>
                  </li>
                  <li>
                    <a href="/in-person-games">In Person Race Nights</a>
                  </li>
                  <li>
                    <a href="/fulfilment">Fulfilment policies</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy</a>
                  </li>
                  <li>
                    <a href="/contact">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="column footer-section">
                <div>
                  <h1>In association with At The Races</h1>
                  <ul>
                    <li>
                      <a
                        href="https://www.attheraces.com/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src="/atr-small.png"
                          alt="At The Races"
                          width="150"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              Copyright track-night.com &copy; 2021 -{' '}
              <a href="https://find-and-update.company-information.service.gov.uk/company/13050425">
                Reg No. 13050425
              </a>
            </div>
          </div>
        </footer>
      </div>
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap');
        .title:hover {
          cursor: pointer;
        }
        .hero-body {
          padding: 0.75em 1.5em 1.5em 1.5em;
        }
        .footer-section h1 {
          font-size: 1em;
          border-bottom: solid 5px #6d6d6d;
          padding-bottom: 5px;
        }
        .footer-section ul {
          list-style: none;
          margin-left: 0;
        }
        .footer-section ul ul {
          margin-left: 1em;
        }
        .social-links li {
          display: inline;
          font-size: 2em;
          padding-right: 1em;
        }
        .footer {
          background-color: #fafafa;
        }
        .footerLinks {
          margin-bottom: 1em;
          margin-left: 0;
        }
        .footerLinks li {
          display: inline-block;
          border-left: solid 1px #4a4a4a;
          padding: 0em 1em;
        }
        .footerLinks li:first-child {
          border-left: 0;
        }

        .hero-body h1 {
          background: url(/tn-logo-with-text.png) left no-repeat;
          background-size: 450px;
          width: 100%;
          height: 80px;
          margin-bottom: 0;
        }
        .atr .hero-body h1 {
          background: url(/atr1.png) left no-repeat;
          background-size: 450px;
          width: 100%;
          height: 80px;
          margin-bottom: 0;
        }
        .bump-down-17 {
          position: relative;
          top: 17px;
        }
        @media (max-width: 550px) {
          .bump-down-17 {
            position: relative;
            top: 12px;
          }
          .hero-body h1 {
            background: url(/tn-logo-with-text-2.png) left no-repeat;
            background-size: 250px;
            height: 60px;
          }
          .atr .hero-body h1 {
            background: url(/atr2.png) left no-repeat;
            background-size: 280px;
            height: 80px;
          }
        }
        .mobile-links {
          list-style: none;
        }
        .mobile-links a {
          font-size: 1.2em;
          text-align: center;
          color: #666;
          display: block;
        }
        .mobile-links li {
          border-bottom: 1px solid #cbcbcb;
          padding: 10px;
          background-color: #f7f7f7;
        }
        .header-links ul {
          padding-top: 10px;
          list-style: none;
          padding-left: 0;
          margin-left: 0;
        }
        .header-links li {
          padding-left: 0;
          display: inline;
          font-size: 1.2em;
        }
        .header-links li:first-child {
          padding-right: 1.5em;
        }
        .header-links a {
          color: #444;
        }
        .header-links:hover a {
          color: #000;
        }
        .is-4-large-2-small {
          flex: none;
          width: 33.33333%;
        }
        .right-when-big {
          text-align: right;
        }
        .right-when-small {
          display: none;
        }
        .hide-large {
          display: none;
        }

        .banner {
          background-image: url('/top-1.jpg');
          background-repeat: no-repeat;
          background-color: #feb003;
          background-position: left;
        }
        @media (max-width: 768px) {
          .banner {
            background-image: url('/top-2.jpg');
            background-position: right;
          }
          .hide-large {
            display: block;
          }
          .is-4-large-2-small {
            flex: none;
            width: 10%;
          }
          .right-when-small {
            float: right;
            display: inline-block;
          }
          .right-when-big {
            display: none;
          }
        }
        @media (min-width: 769px) {
          .banner {
            background-position: 100% 0;
          }
        }
        @media (min-width: 1000px) {
          .banner {
            background-position: 110% 0;
          }
        }
        @media (min-width: 1500px) {
          .banner {
            background-position: 140% 0;
          }
        }
        @media (min-width: 1700px) {
          .banner {
            background-position: 150% 0;
          }
        }
        @media (min-width: 1800px) {
          .banner {
            background-position: 230% 0;
          }
        }
      `}</style>
      <style global jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
        body {
          font-family: 'Noto Sans JP', sans-serif;
        }
        p {
          margin-bottom: 1em;
        }
      `}</style>
    </div>
  );
};

export default Layout;
