import fetch from "isomorphic-unfetch";
import getConfig from "next/config";
import nextCookie from "next-cookies";

const { publicRuntimeConfig } = getConfig();
const { API_URL } = publicRuntimeConfig;

export const fetchWithSessionCookie = (
  path,
  ctx,
  options = {},
  noContentHeader
) => {
  const cookies = nextCookie(ctx);
  const optionsWithSession = Object.assign(options, {
    credentials: "include",
    headers: {
      Cookie: `connect.sid=${cookies["connect.sid"]}`,
    },
  });
  if (!noContentHeader) {
    optionsWithSession.headers["Content-Type"] =
      (options.headers && options.headers.contentType) || "application/json";
  }
  return fetch(
    `${ctx.brand ? ctx.brand.rootUrl : API_URL}${path}`,
    optionsWithSession
  );
};

export default fetchWithSessionCookie;
